// @flow

import dashboardService from 'dashboard/service';
import type { TDashboardConfigResponse } from '../service';
import { handleApiError } from '_common/utils/utils';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { observable, action, runInAction, reaction } from 'mobx';
import { get } from 'lodash';
import stores from '../../stores';
import { RESOURCES } from '_common/constants/acl';
import { STATUSES, TYPES } from '_common/constants/dashboard';

type TDashboardType = $Values<typeof TYPES>;

export type TPeriscopeFilter = {
  filter_name: string,
  column_value: string,
};

export type TDashboardDDConfig = TDashboardConfigResponse;

export type TDashboardConfig = TDashboardConfigResponse & {
  onDrillDown: (filters: TPeriscopeFilter[]) => Promise<TDashboardDDConfig>,
};

class DashboardStore {

  constructor() {
    window.addEventListener('message', this.onFrameMessage, false);
    reaction(
      () => this.currentCompany,
      () => {
        if (stores.aclStore.isStrictAccessGranted(RESOURCES.DASHBOARD)) {
          this.loadConfig(this.currentCompany);
        }
      }
    );
  }

  @observable
  currentCompany: TCompany | Object = {};

  @observable
  iframeConfig: ?TDashboardConfig = null;

  @observable
  drillDownConfig: ?TDashboardDDConfig = null;

  @observable
  status: string = STATUSES.LOADING;

  @observable
  dashboardLoadedForCompanyId: string;

  @action
  setCurrentCompany = (currentCompany: TCompany | Object) => {
    this.currentCompany = currentCompany;
  };

  getType(rootCompanyId: string): TDashboardType {
    return get(TYPES, rootCompanyId);
  }

  getRootCompanyId = (company: TCompany | Object) => {
    let rootCompanyId = '';
    if (company && TYPES[company.companyId]) {
      rootCompanyId = company.companyId;
    } else if (company) {
      const companiesHierarchy = company.hierarchy;
      if (companiesHierarchy) {
        if (companiesHierarchy.length > 1) {
          rootCompanyId =
            companiesHierarchy[0] === MAIN_COMPANIES.DODDLE_ID
              ? companiesHierarchy[1]
              : companiesHierarchy[0];
        } else if (companiesHierarchy.length === 1) {
          throw new Error('Dashboard is not configured');
        }
      }
    }
    return rootCompanyId;
  };

  @action
  setStatus = (status: string) => {
    runInAction(() => {
      this.status = status;
    });
  };

  @action
  async loadConfig(company: TCompany | Object) {
    if (company.companyId === this.dashboardLoadedForCompanyId) {
      return;
    }
    try {
      let config = null;
      this.setStatus(STATUSES.LOADING);
      this.dashboardLoadedForCompanyId = company.companyId;
      let rootCompanyId;
      try {
        rootCompanyId = this.getRootCompanyId(company);
      } catch {
        this.setStatus(STATUSES.NOT_CONFIGURED);
        return;
      }
      const dashboardType = this.getType(rootCompanyId);

      switch (dashboardType) {
        case TYPES.DODDLE:
        case TYPES.SUR: {
          //SUR is basically the only company using this dashboard now
          const response = await dashboardService.getDashboardConfig(
            company.companyId,
            rootCompanyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getDashboardConfigDrillDown(
              company.companyId,
              rootCompanyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.AUSTRALIA_POST: {
          const response = await dashboardService.getApHostDashboardConfig(
            company.companyId,
            rootCompanyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getApHostDashboardConfigDrillDown(
              company.companyId,
              rootCompanyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.YAMATO: {
          const response = await dashboardService.getYamatoDashboardConfig(
            company.companyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getYamatoDashboardConfigDrillDown(
              company.companyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.AMAZON: {
          const response = await dashboardService.getAmazonDashboardConfig(
            company.companyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getAmazonDashboardConfigDrillDown(
              company.companyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.STARLINKS: {
          const response =
            await dashboardService.getStarlinksHostDashboardConfig(
              company.companyId,
              rootCompanyId
            );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getStarlinksHostDashboardConfigDrillDown(
              company.companyId,
              rootCompanyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.USPS: {
          const response = await dashboardService.getUspsHostDashboardConfig(
            company.companyId,
            rootCompanyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getUspsHostDashboardConfigDrillDown(
              company.companyId,
              rootCompanyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        case TYPES.ASDA: {
          const response = await dashboardService.getAsdaDashboardConfig(
            company.companyId
          );

          const onDrillDown = (
            filters: TPeriscopeFilter[]
          ): Promise<TDashboardDDConfig> => {
            return dashboardService.getAsdaDashboardConfigDrillDown(
              company.companyId,
              filters[0]
            );
          };
          config = {
            ...response,
            onDrillDown,
          };
          break;
        }
        default: {
          this.setStatus(STATUSES.ERROR);
          handleApiError(
            `Unknown rootCompanyId - ${rootCompanyId}`,
            'Could not load dashboard'
          );
          return;
        }
      }
      this.setStatus(STATUSES.LOADED);
      runInAction(() => {
        this.iframeConfig = config;
        this.drillDownConfig = null;
      });
    } catch (e) {
      this.setStatus(STATUSES.ERROR);
      handleApiError(e, 'Could not load dashboard');
    }
  }

  onFrameMessage = (event: MessageEvent) => {
    const messageData = event.data;
    if (messageData === null || typeof messageData !== 'object') return;
    if (messageData.event_type !== 'drilldown') return;
    if (!Array.isArray(messageData.filter_values)) return;
    const filters: Array<TPeriscopeFilter> = [];
    for (const item of messageData.filter_values) {
      if (item === null || typeof item !== 'object') continue;
      filters.push({
        // eslint-disable-next-line camelcase
        filter_name: String(item.filter_name),
        // eslint-disable-next-line camelcase
        column_value: String(item.column_value),
      });
    }
    this.handleDrillDown(filters);
  };

  handleDrillDown = async (filters: TPeriscopeFilter[]) => {
    if (!this.iframeConfig) return;
    const { onDrillDown } = this.iframeConfig;
    if (onDrillDown) {
      try {
        const ddConfig = await onDrillDown(filters);
        runInAction(() => {
          this.drillDownConfig = ddConfig;
        });
      } catch (e) {
        handleApiError(e, 'Could not get drilldown config');
      }
    }
  };

  handleDrillOut = () => {
    this.drillDownConfig = null;
  };

}

export default DashboardStore;
