export const STATUSES = {
  LOADING: 'LOADING',
  NOT_CONFIGURED: 'NOT_CONFIGURED',
  ERROR: 'ERROR',
  LOADED: 'LOADED',
};

export const TYPES = {
  DODDLE: 'DODDLE',
  AUSTRALIA_POST: 'AUSTRALIA_POST',
  YAMATO: 'YAMATO',
  AMAZON: 'AMAZON',
  USPS: 'USPS',
  STARLINKS: 'STARLINKS',
  SUR: 'SUR',
  ASDA: 'ASDA',
};

export const APPLICATION_NAME = 'admintool';

export const NAMES = {
  PBD_OPS_PERFORMANCE: 'pbdOpsPerformance',
  HOST_SUMMARY: 'hostSummary',
  OPS_SUMMARY: 'opsSummary',
  RETURNS_KIOSK: 'returnsKiosk',
  HANDHELD: 'handheld',
};

export const SPACES = {
  DOODLE: 'doddle',
  APAC: 'apac',
  YAMATO: 'yamato',
  AMAZON: 'amazon',
  STARLINKS: 'starlinks',
  USPS: 'usps',
  ASDA: 'asda',
};
