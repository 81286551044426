// @flow

import {
  observable,
  computed,
  values,
  action,
  runInAction,
  reaction,
  toJS,
} from 'mobx';
import storesService from '_common/services/storesService';
import configService from '_common/services/configService';
import {
  get,
  unset,
  difference,
  chunk,
  omit,
  isEmpty,
  set,
  range,
} from 'lodash';
import moment from 'moment';
import commonActions from '_common/actions';
import {
  MESSAGE_TYPE,
  STORE_ACTIVATION_DATE_FORMAT,
} from '_common/constants/appConfig';
import loqateService from '_common/services/loqateService';
import {
  API_MAX_STORES_LIMIT,
  API_MAX_STORES_LIMIT_WITH_FEW_FIELDS,
  INVALID_TIME_RANGE_MESSAGE,
  OPEN_CLOSE_SETTERS,
  STATUSES,
  PAGINATION,
  STORE_USER_LOCATIONS,
} from '_common/constants/stores';
import { default as mobxStores } from 'stores';
import {
  concurrentRequests,
  handleApiError,
  mergeParams,
} from '_common/utils/utils';
import urls from '_common/routes/urls';
import { RESOURCES } from '_common/constants/acl';
import showSuccessMessage from '_common/actions/showSuccessMessage';

class DoddleStores {

  constructor() {
    reaction(
      () => this.currentCompany,
      () => {
        const mainStoresAccess = mobxStores.aclStore.isStrictAccessGranted(
          RESOURCES.STORES.MAIN
        );
        const restrictedStoresAccess =
          mobxStores.aclStore.isStrictAccessGranted(
            RESOURCES.STORES.RESTRICTED_LIST
          );

        if (mainStoresAccess) {
          this.setCompanyHasProposedStore(this.currentCompany);
        }

        if (mainStoresAccess || restrictedStoresAccess) {
          this.isStoresEmpty = false;
          let resetFiltersFlag = true;
          let additionalDefaultFilters = {};
          if (mobxStores.aclStore.isNetworkRole) {
            additionalDefaultFilters = { status: STATUSES.PLANNED };
          }
          if (mobxStores.aclStore.isStoreRole) {
            resetFiltersFlag = false;
          }
          if (resetFiltersFlag) {
            this.resetFilters(additionalDefaultFilters);
          }
        }
      }
    );

    // When filters on stores list page are changed
    reaction(
      () => this.filters,
      () => {
        this.paginationConfig.current = 1;
        this.loadStoresByFilters();
      }
    );

    // When sorting on stores list page is changed
    reaction(
      () => this.sorting,
      () => {
        this.loadStoresByFilters();
      }
    );
  }

  @observable
  stores: Map<string, TStore> = new Map();

  @observable
  storesToExportSelected: Object = {
    keys: [],
    stores: [],
  };

  @observable
  filters: TStoresFilterParams = {
    host: '',
  };

  @observable
  sorting: TStoresSortingParams = {
    sortBy: '',
    sortDescending: false,
  };

  @observable
  paginationConfig: TStorePaginationConfig = {
    current: 1,
    pageSize: 10,
    hideOnSinglePage: true,
    hideLastPage: false,
    showSizeChanger: false,
    onChange: this.loadStoresByFilters.bind(this),
    showLessItems: false,
  };

  @observable
  companyHasProposedStores: boolean = false;

  @observable
  commonSubCountries: Object = {};

  /**
   * Currently only RETURNS_KIOSK locations in the US use different mapping
   * @type {Object}
   */
  @observable
  overrideSubCountries: Object = {};

  @observable
  storeOptions: {
    raw: Map<string, TStore>,
    options: Array<TOption>,
  } = {
    raw: new Map(),
    options: [],
  };

  @observable
  storeOptionsConfig: TStoreOptionsConfig = {
    lastLoadedFromCompany: '',
    lastQuery: '',
    totalRecords: 0,
  };

  @observable
  organisations: Array<TOrganisation> = [];

  @observable
  isStoresLoading: boolean = false;

  @observable
  isStoresLoaded: boolean = false;

  @observable
  isStoresEmpty: boolean = false;

  @observable
  storesMappedByOrganization: Map<string, TStore> = new Map();

  @observable
  isFiltersEmpty: boolean = true;

  @observable
  singleStore: Object = {};

  @observable
  dashboardStore: Object = {};

  @observable
  addresses: Array<TAddressModalData> = [];

  @observable
  filteredAddresses: Array<TAddressModalData> = [];

  @observable
  isAddressFilterEmpty: boolean = true;

  @observable
  isAddressModalVisible: boolean = false;

  @observable
  currentCompany: string = '';

  @observable
  resetFiltersFlag: boolean = false;

  @observable
  loadingCountriesConfig: boolean = false;

  @observable
  updateStoresProgress: number = 0;

  @computed
  get getCountriesOptions(): Array<TOption> {
    return Object.entries(this.commonSubCountries).map(
      ([countryCode, config]) => ({
        // $FlowExpectedError
        label: config.label,
        value: countryCode,
      })
    );
  }

  @computed
  get getCompanyHasProposedStoresField(): boolean {
    return this.companyHasProposedStores;
  }

  @computed
  get getTotalRecords(): ?number {
    return this.paginationConfig.total;
  }

  @computed
  get getUpdateStoresProgressField(): number {
    return this.updateStoresProgress;
  }

  @computed
  get getSelectedStoreKeys(): Array<TStore> {
    return toJS(this.storesToExportSelected.keys);
  }

  @computed
  get getSelectedStoresToExport(): Array<TStore> {
    return toJS(this.storesToExportSelected.stores);
  }

  @computed
  get getStoreOptionsField(): Array<TOption> {
    return values(this.storeOptions.options);
  }

  @action
  setUpdateStoresProgress = (progress: number): void => {
    this.updateStoresProgress = progress;
  };

  updateSingleStore = (store: TStore) => {
    const storeId = store.storeId;
    if (!storeId) return;
    if (storeId === this.singleStore.storeId) {
      const newStoreData = {
        ...this.singleStore,
        ...store,
      };
      if (
        difference(Object.keys(this.singleStore), Object.keys(store)).includes(
          'externalStoreId'
        )
      ) {
        unset(newStoreData, 'externalStoreId');
      }

      this.singleStore = newStoreData;
    }
    const storeInTheList = this.stores.get(storeId);
    if (storeInTheList) {
      this.stores.set(storeId, { ...storeInTheList, ...store });
    }
  };

  @computed
  get getStoresField(): Array<TStore> {
    return values(this.stores);
  }

  @computed
  get getPaginationConfigField(): Object {
    return this.paginationConfig;
  }

  @action
  setCurrentCompany = (currentCompany: string) => {
    this.currentCompany = currentCompany;
  };

  getRawStoreOption = (storeId: string): ?TStore => {
    return this.storeOptions.raw.get(storeId);
  };

  @action
  loadCountriesConfig = async () => {
    if (this.loadingCountriesConfig) {
      return;
    }
    this.loadingCountriesConfig = true;
    if (
      !isEmpty(this.overrideSubCountries) &&
      !isEmpty(this.commonSubCountries)
    ) {
      this.loadingCountriesConfig = false;
      // Make request to config api only once per application session
      return;
    }
    try {
      const result = await configService.getCountriesConfig();
      runInAction(() => {
        this.commonSubCountries = result.common;
        this.overrideSubCountries = result.locationTypeOverrides;
        this.loadingCountriesConfig = false;
      });
    } catch (e) {
      handleApiError(e, 'Could not load countries config');
    }
  };

  @action
  setSelectedStoresToExport = (keys: Array<string>, stores: Array<TStore>) => {
    this.storesToExportSelected = {
      keys,
      stores,
    };
  };

  @action
  storesBulkApprove = async (
    stores: Array<{
      storeId: string,
      companyId: string,
      activationDate?: Object,
    }>
  ): Promise<number> => {
    let approvedStores = 0;
    // StoreIDS are divided into chunks of fixed size not to send all requests at once
    const storesChunks = chunk(stores, 10);
    for (const chunk of storesChunks) {
      const approveStoreRequests = [];
      const updateActivationDateConfigs = [];
      for (const storeToApprove of chunk) {
        const { storeId, companyId, activationDate } = storeToApprove;
        approveStoreRequests.push(
          storesService
            .approveStore(storeId, companyId)
            .catch((_) => console.error(`Could not approve store ${storeId}`))
        );
        if (moment.isMoment(activationDate)) {
          updateActivationDateConfigs.push({
            storeId,
            setterMode: 'setopeningdate',
            companyId,
            date: activationDate.format(STORE_ACTIVATION_DATE_FORMAT),
          });
        }
      }
      const chunkResult = await Promise.all(approveStoreRequests);

      // It's required to initiate setOpeningDate requests ONLY after stores are approved
      await Promise.all(
        updateActivationDateConfigs.map((config) =>
          storesService
            // Flow wants a redundant check for config.companyId
            //$FlowFixMe
            .setOpeningOrClosingDate(config)
            .catch((_) =>
              console.error(
                `Could not set opening date for store ${config.storeId}`
              )
            )
        )
      );

      runInAction(() => {
        this.updateStoresProgress += Math.round(100.0 / storesChunks.length);
      });
      approvedStores += chunkResult.reduce(
        (accumulator, value) => (value ? accumulator + 1 : accumulator),
        0
      );
    }
    return approvedStores;
  };

  haveStoreWithName(storeName: string) {
    const stores = this.stores;
    return computed(() => {
      if (!storeName) return false;

      let result = false;
      stores.forEach((store) => {
        if (
          store.storeName &&
          store.storeName.toLowerCase() === storeName.toLowerCase()
        ) {
          result = true;
        }
      });
      return result;
    }).get();
  }

  getSingleStoreById = async (
    storeId: string,
    sensitiveDataFlag: boolean = true
  ) => {
    let store = {};
    try {
      store = await storesService.getSingleStoreById(
        storeId,
        sensitiveDataFlag
      );
    } catch (e) {
      handleApiError(e, `Could not get store by id`, {
        messageSubData: { storeId },
        hide404Error: true,
        showNativeErrors: true,
      });
    }
    return store;
  };

  @action
  loadSingleStore = async (
    storeId: string,
    {
      saveForDashboard = false,
      sensitiveDataFlag = true,
    }: {
      sensitiveDataFlag?: boolean,
      saveForDashboard?: boolean,
      loadQRCodeForLogin?: string,
    }
  ) => {
    try {
      const singleStore = await storesService.getSingleStoreById(
        storeId,
        sensitiveDataFlag
      );
      const openingHours = await this.getStoreOpeningHours(storeId);
      runInAction(() => {
        if (saveForDashboard) {
          this.dashboardStore = { ...singleStore, openingHours };
        } else {
          this.singleStore = { ...singleStore, openingHours };
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  @action
  getStoreOpeningHours = async (storeId: string) => {
    let openingHours = {};
    try {
      openingHours = await storesService.getStoreOpeningHoursById(storeId);
    } catch (e) {
      console.error(e);
    }
    return openingHours;
  };

  @action
  resetSingleStore = async () => {
    runInAction(() => {
      this.singleStore = {};
    });
  };

  sendOnboardingEmail = async (storeId: string) => {
    try {
      await storesService.sendOnboardingEmail(storeId);
      showSuccessMessage('Onboarding email has been sent');
    } catch (e) {
      handleApiError(e, 'Could not send onboarding email');
    }
  };

  @action
  updateStoreById = async (
    storeId: string,
    data: Object,
    companyId: string,
    patch?: boolean,
    rev?: string,
    callback?: () => void
  ): Promise<boolean> => {
    let result = false;
    try {
      let singleStore = await storesService.updateStoreById(
        storeId,
        data,
        companyId
      );
      singleStore = await storesService.getSingleStoreById(storeId);
      runInAction(() => {
        this.updateSingleStore(singleStore);
        callback && callback();
      });
      result = true;
    } catch (e) {
      this.handleCreateUpdateApiError(e, 'Could not update store');
    }
    return result;
  };

  @action
  updateStoreOpeningHours = async (storeId: string, data: any) => {
    const openHoursDefaults = {
      sunday: { isOpen: false },
      saturday: { isOpen: false },
      tuesday: { isOpen: false },
      wednesday: { isOpen: false },
      thursday: { isOpen: false },
      friday: { isOpen: false },
      monday: { isOpen: false },
    };

    try {
      const openingHours = await storesService.updateStoreOpeningHours(
        storeId,
        {
          openingHours: Object.assign({}, openHoursDefaults, data.openingHours),
        }
      );
      runInAction(() => {
        this.updateSingleStore({ storeId, openingHours });
      });
    } catch (e) {
      commonActions.showApiError(
        get(
          INVALID_TIME_RANGE_MESSAGE.split('.'),
          '[0]',
          INVALID_TIME_RANGE_MESSAGE
        )
      );
      console.error(e);
    }
  };

  @action
  updateStorePhotos = async (storeId: string, data: TStorePhotos) => {
    try {
      const companyId = this.singleStore.companyId;
      const { uploadedPhotos } = await storesService.updateStorePhotos(
        storeId,
        companyId,
        data
      );
      if (uploadedPhotos && uploadedPhotos.length > 0) {
        const [photoName, value] = Object.entries(uploadedPhotos[0])[0];
        runInAction(() => {
          const tempStore = { ...this.singleStore };
          set(tempStore, `place.photos.${photoName}`, value);
          this.singleStore = tempStore;
        });
      } else {
        console.error(
          'updateStorePhotos:: updateStorePhotos received empty response'
        );
      }
    } catch (e) {
      handleApiError(e, 'Could not update photos');
    }
  };

  @action
  setAddressModalVisible = (isAddressModalVisible: boolean) => {
    runInAction(() => {
      this.isAddressModalVisible = isAddressModalVisible;
      if (!isAddressModalVisible) {
        this.addresses = [];
      }
    });
  };

  @action
  setFilteredAddresses = (
    collection: Array<TAddressModalData>,
    isFiltersEmpty: boolean
  ) => {
    this.filteredAddresses = collection;
    this.isAddressFilterEmpty = isFiltersEmpty;
  };

  @action
  resetFilteredAddresses = () => {
    this.filteredAddresses = [];
    this.isAddressFilterEmpty = true;
  };

  @computed
  get getFilteredAddresses(): Array<TAddressModalData> {
    return values(this.filteredAddresses);
  }

  @action
  setSorting = (sorting: TStoresSortingParams) => {
    this.sorting = mergeParams(this.sorting, sorting);
  };

  @action
  setFilters = (filters: TStoresFilterParams) => {
    this.filters = mergeParams(this.filters, filters);
  };

  @computed
  get getFiltersField(): TStoresFilterParams {
    return this.filters;
  }

  @action
  async setCompanyHasProposedStore(companyId: string): Promise<void> {
    this.companyHasProposedStores = false;
    try {
      const { pagination } = await storesService.getStoresByFilters(
        { host: companyId, status: STATUSES.PROPOSED },
        1,
        1
      );
      runInAction(() => {
        this.companyHasProposedStores = pagination && !!pagination.totalRecords;
      });
    } catch (e) {
      console.error(e);
    }
  }

  getAllProposedStoresForCurrentCompany(): Promise<Array<TStore>> {
    return this.getAllStoresByFilters({
      host: this.currentCompany,
      status: STATUSES.PROPOSED,
    });
  }

  @action
  reloadStoresByFilters = (): void => {
    this.loadStoresByFilters(
      this.paginationConfig.current,
      this.paginationConfig.pageSize
    );
  };

  @action
  async loadStoresByFilters(
    page: number = 1,
    limit: number = PAGINATION.PAGE_LIMIT
  ) {
    if (this.isStoresLoading) return;
    this.isStoresLoading = true;

    try {
      const hasSpecificFilters = !isEmpty(omit(this.filters, ['host']));
      let stores = [];
      let pagination = {};
      const { storeId, host, status, storeName } = this.filters;
      if (storeId) {
        const store = await this.getSingleStoreById(storeId, false);

        const isStoreMatchFilters =
          !isEmpty(store) &&
          (!host || (host && store.companyId === host)) &&
          (!storeName ||
            (storeName &&
              store.storeName &&
              store.storeName.includes(storeName))) &&
          (!status || (status && store.status === status));

        if (isStoreMatchFilters) {
          stores = [store];
          pagination = { totalRecords: 1 };
        }
      } else {
        const storesResponse = await storesService.getStoresByFilters(
          {
            includeOpeningHours: true,
            sensitiveDataFlag: true,
            ...this.filters,
            ...this.sorting,
          },
          page,
          limit
        );
        stores = storesResponse.stores;
        pagination = storesResponse.pagination;
      }
      runInAction(() => {
        this.stores.clear();
        const hideLastPage = pagination
          ? PAGINATION.HIDE_LAST_PAGE_STORES_THRESHOLD <
              pagination.totalRecords &&
            pagination.totalRecords / PAGINATION.PAGE_LIMIT - page >
              PAGINATION.REVEAL_WHEN_REACHED_LAST_PAGE
          : false;
        this.paginationConfig = {
          ...this.paginationConfig,
          total: pagination ? pagination.totalRecords : 0,
          current: page,
          hideLastPage,
        };
        this.paginationConfig.total = pagination.totalRecords;
        this.paginationConfig.current = page;
        if (stores.length) {
          //$FlowFixMe
          stores.forEach((store: TStore) => {
            if (store.storeId) this.stores.set(store.storeId, store);
          });
        } else if (!hasSpecificFilters) {
          this.isStoresEmpty = true;
        }
      });
    } catch (e) {
      handleApiError(e, 'StoreV3 API is unavailable', {
        custom404Handler: () => {
          runInAction(() => {
            this.isStoresEmpty = true;
          });
        },
      });
    } finally {
      runInAction(() => {
        this.isStoresLoading = false;
        this.isStoresLoaded = true;
      });
    }
  }

  getSingleStoreByIdAndFilters = async (
    filters: TStoresFilterParams
  ): Promise<?TStore | Object> => {
    let result = null;
    if (filters.storeName) {
      const store = await this.getSingleStoreById(filters.storeName, false);
      const { countrySubdivision, country, host, status } = filters;
      const isStoreExistAndMatchFilters =
        store &&
        (!host || store.companyId === host) &&
        (!country || get(store, 'place.address.country') === country) &&
        (!countrySubdivision ||
          get(store, 'place.countrySubdivision') === countrySubdivision) &&
        (!status || store.status === status);
      if (isStoreExistAndMatchFilters) {
        result = store;
      }
    }
    return result;
  };

  getAllStoresByFilters = async (
    filters: TStoresFilterParams
  ): Promise<Array<TStore>> => {
    let stores: Array<TStore> = [];
    let storesPerRequestLimit = API_MAX_STORES_LIMIT;
    if (filters.fieldsToReturn) {
      storesPerRequestLimit = API_MAX_STORES_LIMIT_WITH_FEW_FIELDS;
    }
    try {
      const initialRequest = await storesService.getStoresByFilters(
        filters,
        1,
        storesPerRequestLimit
      );
      const { pagination, stores: initialStores } = initialRequest;
      stores = stores.concat(initialStores);
      if (pagination.hasNextPage) {
        const pagesAmount = Math.ceil(
          pagination.totalRecords / storesPerRequestLimit
        );
        const pagesList = range(2, pagesAmount + 1);
        const getStoresRequestClosure = (page: number) =>
          storesService
            .getStoresByFilters(filters, page, storesPerRequestLimit)
            .then((response) => {
              stores = stores.concat(response.stores);
            });
        await concurrentRequests(pagesList, getStoresRequestClosure);
      }
    } catch (e) {
      handleApiError(e, 'Could not get all stores by filters');
    }
    return stores;
  };

  convertStoresToOptions = (stores: Array<TStore>): Array<TOption> => {
    let options = [];
    if (stores.length) {
      options = stores.map(
        ({ storeName, companyStoreId, storeId }: TStore) => ({
          label: `${storeName ? storeName : '-'} ${
            companyStoreId ? `(${companyStoreId})` : ''
          }`,
          value: storeId,
        })
      );
    }
    return options;
  };

  @action
  getStoreOptionsByFilters = async (
    filters: TStoresFilterParams,
    saveStoreOptions: boolean = false,
    page: number = 1,
    limit: number = API_MAX_STORES_LIMIT
  ) => {
    let storeOptions = [];
    try {
      const { stores, pagination } = await storesService.getStoresByFilters(
        {
          ...filters,
          fieldsToReturn: 'storeId,companyStoreId,storeName,companyId,status',
        },
        page,
        limit
      );
      storeOptions = this.convertStoresToOptions(stores);
      runInAction(() => {
        this.saveStoreOptions(
          stores,
          storeOptions,
          pagination.totalRecords,
          filters.host,
          saveStoreOptions
        );
      });
    } catch (e) {
      handleApiError(e, 'Could not load store options', {
        hide404Error: true,
      });
    }
    return storeOptions;
  };

  @action
  saveStoreOptions = (
    rawStores: Array<TStore>,
    storeOptions: Array<TOption>,
    totalRecords: number,
    companyId?: string,
    saveOptions: boolean
  ): void => {
    if (saveOptions) {
      this.storeOptions.raw.clear();
      rawStores.forEach((store: TStore) => {
        if (store.storeId) this.storeOptions.raw.set(store.storeId, store);
      });
      this.storeOptions.options = storeOptions;
    }
    if (companyId) {
      this.storeOptionsConfig.lastLoadedFromCompany = companyId;
      this.storeOptionsConfig.totalRecords = totalRecords;
    }
  };

  @action
  setStoreOptionsConfig = (config: TStoreOptionsConfig) => {
    runInAction(() => {
      this.storeOptionsConfig = {
        ...this.storeOptionsConfig,
        ...config,
      };
    });
  };

  /**
   * Method is used to make a callback for react-select/Async or SelectAsync (custom component from antd Select).
   *
   * Predefined filters are passed in the first function which returns a function that will be used
   * as a callback. Query parameter can be a storeName or storeId.
   *
   */
  @action
  getStoreOptionsByFiltersCallback =
    (
      filters: TStoresFilterParams,
      saveOptions: boolean = false,
      forceLoad: boolean = false,
      page: number = 1,
      limit: number = API_MAX_STORES_LIMIT
    ) =>
    async (query: string): Promise<Array<TOption>> => {
      this.setStoreOptionsConfig({ lastQuery: query });
      let storeOptions = [];
      const companyId = filters.host;
      const canGetCacheValues =
        !query &&
        !forceLoad &&
        this.storeOptions.options.length &&
        companyId === this.storeOptionsConfig.lastLoadedFromCompany;
      if (canGetCacheValues) {
        return values(this.storeOptions);
      } else {
        const newFilters = {
          ...filters,
          storeName: query,
        };
        const store = await this.getSingleStoreByIdAndFilters(newFilters);
        if (store) {
          storeOptions = this.convertStoresToOptions([store]);
          runInAction(() => {
            this.saveStoreOptions(
              // $FlowFixMe
              [store],
              storeOptions,
              1,
              companyId,
              saveOptions
            );
          });
        } else {
          storeOptions = await this.getStoreOptionsByFilters(
            newFilters,
            saveOptions,
            page,
            limit
          );
        }
        if (saveOptions && !storeOptions.length) {
          runInAction(() => {
            this.saveStoreOptions([], [], 0, companyId, saveOptions);
          });
        }
      }
      return storeOptions;
    };

  @action
  getStoresByCompanyId = async (
    companyId: string,
    checkIsLoading: boolean = false
  ) => {
    if (!companyId || (checkIsLoading && this.isStoresLoading)) return;

    this.stores.clear();
    this.isStoresLoading = true;

    try {
      const stores: Array<TStore> = await storesService.getStoresByCompany(
        companyId
      );
      runInAction(() => {
        //$FlowFixMe
        if (stores.length) {
          stores.forEach((store: TStore) => {
            if (store.storeId) this.stores.set(store.storeId, store);
          });
        } else {
          this.isStoresEmpty = true;
        }
        this.isStoresLoading = false;
        this.isStoresLoaded = true;
      });
    } catch (e) {
      const status = get(e, 'response.status');
      if (status) {
        switch (status) {
          case 404: {
            runInAction(() => {
              this.isStoresLoading = false;
              this.isStoresLoaded = true;
              this.isStoresEmpty = true;
            });
            break;
          }
          case 500:
          default: {
            runInAction(() => {
              this.isStoresLoading = false;
            });
            commonActions.showApiError('StoreV3 API is unavailable');
            console.error(e);
          }
        }
      }
    }
  };

  @action
  getStoreById = (storeId: string): ?TStore => {
    return this.stores.get(storeId);
  };

  @action
  uploadStoresFile = async (file: Object): Promise<TStoresFileUploadResult> => {
    this.updateStoresProgress = 100;
    return storesService.uploadStoresFile(file);
  };

  @action
  storesBulkCreate = async (
    stores: Array<TStore>
  ): Promise<TStoresBulkUploadResult> => {
    const storesToCreate = [...stores];
    let amountAffected = 0;
    const oneStoreProgressPercent = Math.round(100.0 / storesToCreate.length);
    const failedIndexes = [];
    const companyStores = {};

    const createStoreCallback = (openingHours: ?Object) => (store: TStore) => {
      amountAffected++;
      runInAction(() => {
        this.updateStoresProgress += oneStoreProgressPercent;
      });
      if (store && store.storeId && store.companyId) {
        const { storeId, companyId } = store;
        if (companyStores[companyId]) {
          companyStores[companyId] = [...companyStores[companyId], storeId];
        } else {
          companyStores[companyId] = [storeId];
        }
        storesService
          //$FlowFixMe
          .updateStoreOpeningHours(storeId, openingHours)
          .catch((e) =>
            console.error(
              e,
              //$FlowFixMe
              `Could not update opening hours for ${storeId}`
            )
          );
      }
    };

    const createStoreClosure = (storeToCreate: Object) => {
      const { index: storeIndex, companyId, openingHours } = storeToCreate;
      const storeV3 = omit(storeToCreate, [
        'index',
        'openingHours',
        'companyId',
      ]);

      return (
        storesService
          // $FlowFixMe
          .createStore(companyId, storeV3)
          .then(createStoreCallback(openingHours))
          .catch((e) => {
            console.error(e);
            const errors = get(e, 'response.data.errors', []);
            failedIndexes.push({
              storeIndex,
              message: errors.map((e) => e.message).join(', '),
            });
          })
      );
    };
    await concurrentRequests(storesToCreate, createStoreClosure);

    for (const [companyId, createdStoreIds] of Object.entries(companyStores)) {
      // $FlowFixMe
      mobxStores.usersStore.createUserForStores(companyId, createdStoreIds);
    }

    return {
      amountAffected,
      failedIndexes,
    };
  };

  @action
  storesBulkUpdate = async (
    stores: Array<TStore>
  ): Promise<TStoresBulkUploadResult> => {
    const storesToUpdate = [...stores];
    let amountAffected = 0;
    const oneStoreProgressPercent = Math.round(100.0 / storesToUpdate.length);
    const failedIndexes = [];

    const updateStoreCallback =
      (openingHours?: ?Object, openingDate?: string, closingDate?: string) =>
      (store: TStore) => {
        amountAffected++;
        runInAction(() => {
          this.updateStoresProgress += oneStoreProgressPercent;
        });
        if (store && store.storeId && store.companyId) {
          const { storeId, companyId } = store;
          if (openingDate) {
            this.setOpeningOrClosingDate({
              storeId,
              setterMode: OPEN_CLOSE_SETTERS.SET_OPENING_DATE,
              companyId,
              date: moment(openingDate).format('YYYY-MM-DD'),
            });
          }
          if (closingDate) {
            this.setOpeningOrClosingDate({
              storeId,
              setterMode: OPEN_CLOSE_SETTERS.SET_CLOSING_DATE,
              companyId,
              date: moment(closingDate).format('YYYY-MM-DD'),
            });
          }
          if (openingHours) {
            storesService
              //$FlowFixMe
              .updateStoreOpeningHours(store.storeId, openingHours)
              .catch((e) =>
                console.error(
                  e,
                  //$FlowFixMe
                  `Could not update opening hours for ${store.storeId}`
                )
              );
          }
        }
      };

    const updateStoreClosure = (storeToUpdate: Object) => {
      const {
        index: storeIndex,
        companyId,
        openingHours,
        storeId,
        openingDate,
        closingDate,
      } = storeToUpdate;
      const storeV3 = omit(storeToUpdate, [
        'index',
        'openingHours',
        'companyId',
        'openingDate',
        'closingDate',
        'storeId',
      ]);

      return (
        storesService
          // $FlowFixMe
          .updateStoreById(storeId, storeV3, companyId)
          .then(updateStoreCallback(openingHours, openingDate, closingDate))
          .catch((e) => {
            console.error(e);
            const errors = get(e, 'response.data.errors', []);
            failedIndexes.push({
              storeIndex,
              message: errors.map((e) => e.message).join(', '),
            });
          })
      );
    };
    await concurrentRequests(storesToUpdate, updateStoreClosure);

    return {
      amountAffected,
      failedIndexes,
    };
  };

  @action
  createStore = async (data: any) => {
    let store = { storeId: '' };
    const {
      general: {
        companyStoreId,
        storeName,
        isDemo,
        geo,
        locationType,
        externalStoreId,
        companyId,
        place,
        includeInOnboardingFeed,
      },
      services,
      availability,
      photos,
      printers,
    } = data;

    try {
      const storeV3Object = {
        companyStoreId,
        storeName,
        isDemo,
        geo,
        locationType,
        externalStoreId,
        ...availability,
        place,
        services: { ...services },
        storeConfiguration: {
          includeInOnboardingFeed,
          // requestPowerWhitelisting: config.requestPowerWhitelisting,
          preadviceEndpoint: 'http://someplace.com',
          storeSystemUIConfig: {},
          collectionDeadlineDays: 0,
          technicalConfiguration: { printers },
        },
      };

      store = await storesService.createStore(companyId, storeV3Object);
      store.status = STATUSES.PROPOSED;

      runInAction(() => {
        this.stores.set(store.storeId, store);
      });

      const postCreateStoreRequests = [
        storesService.updateStorePhotos(store.storeId, companyId, photos),
      ];

      if (STORE_USER_LOCATIONS.includes(store.locationType)) {
        postCreateStoreRequests.push(
          mobxStores.usersStore.createUserForStores(companyId, [store.storeId])
        );
      }

      await Promise.all(postCreateStoreRequests);

      mobxStores.uiStore.showMessage('Store has been successfully created.', {
        duration: 5,
        link: `${urls.doddleStores.rootPath}/${store.storeId}`,
        linkText: 'View details',
        type: MESSAGE_TYPE.SUCCESS,
      });
    } catch (e) {
      this.handleCreateUpdateApiError(e, 'Could not create store');
      return Promise.reject();
    }
    return Promise.resolve();
  };

  @computed
  get getAddresses(): Array<TAddressModalData> {
    return values(this.addresses);
  }

  @action
  findAddresses = async (
    config: TFindAddressConfig
  ): Promise<Array<TAddressModalData>> => {
    let addresses = [];
    try {
      //$FlowFixMe
      addresses = await loqateService.findAddresses(config);
      addresses = addresses.map(
        (address: TLoqateFindResult): TAddressModalData => {
          return {
            id: address.Id,
            label: address.Text,
            country: config.country ? config.country : address.Id.split('|')[0],
            address: `${address.Text} ${address.Description}`,
          };
        }
      );
      runInAction(() => {
        this.addresses = addresses;
      });
    } catch (e) {
      handleApiError(e, 'Could not get addresses');
    }
    return addresses;
  };

  retrieveAddress = async (id: string): Promise<TLoqateAddress | null> => {
    let address = null;
    try {
      address = await loqateService.retrieveAddress(id);
    } catch (e) {
      handleApiError(e, 'Could not retrieve address');
    }
    return address;
  };

  getGeoByLocation = async (
    country: string,
    location: string
  ): Promise<TLoqateGeocode | null> => {
    let geoData = null;
    try {
      geoData = await loqateService.retrieveGeo(country, location);
    } catch (e) {
      handleApiError(e, 'Could not get geo data');
    }
    return geoData;
  };

  @action
  getLocationByPostcode = async (
    postcode: string,
    callback: (location: { lat: number, long: number }) => void
  ) => {
    try {
      const location = await storesService.getLocationByPostcode(postcode);
      callback(location);
    } catch (e) {
      console.error(e);
    }
  };

  @action
  getStoresByCompany = (companyId: string): Promise<Array<TStore>> => {
    try {
      return storesService.getStoresByCompany(companyId);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  @action
  approveStore = async (storeId: string, companyId: string) => {
    try {
      await storesService.approveStore(storeId, companyId);
      const store = await storesService.getSingleStoreById(storeId);
      runInAction(() => {
        this.updateSingleStore(store);
      });
      return Promise.resolve();
    } catch (e) {
      handleApiError(e, 'Could not approve store');
      return Promise.reject(e);
    }
  };

  @action
  setOpeningOrClosingDate = async ({
    storeId,
    ...config
  }: {
    storeId: string,
    companyId: string,
    setterMode: string,
    date: string,
    temporary?: boolean,
  }) => {
    try {
      await storesService.setOpeningOrClosingDate({ storeId, ...config });
      const updatedStore = await storesService.getSingleStoreById(storeId);
      runInAction(() => {
        this.updateSingleStore(updatedStore);
      });
    } catch (e) {
      handleApiError(e, 'Could not update store');
    }
  };

  getLoginQRCodePrivate = async (
    storeId: string,
    companyId: string,
    {
      login,
      token,
    }: {
      token?: string,
      login?: string,
    } = {}
  ) => {
    return storesService.getLoginQRCodePrivate(
      storeId,
      companyId,
      login,
      token
    );
  };

  sendLoginQRCodeAttached = async (
    companyId: string,
    storeId: string,
    email: string,
    login?: string
  ) => {
    let result = false;
    try {
      await storesService.sendLoginQRCodeAttached(
        companyId,
        storeId,
        email,
        login
      );
      result = true;
    } catch (e) {
      handleApiError(e, 'Could not send QR code');
    }
    return result;
  };

  @action
  resetFilters = (additionalDefaultFilters?: TStoresFilterParams = {}) => {
    this.filters = { host: this.currentCompany, ...additionalDefaultFilters };
    this.sorting = {};
    this.resetFiltersFlag = !this.resetFiltersFlag;
  };

  @action
  setResetFiltersFlag = (value: boolean): void => {
    this.resetFiltersFlag = value;
  };

  handleCreateUpdateApiError = (e: Object, message: string): void => {
    const nativeErrors = get(e, 'response.data.errors');
    if (
      get(e, 'response.status') === 400 &&
      !isEmpty(nativeErrors) &&
      nativeErrors.find((e) =>
        e.message.includes('Missing required property: postcode')
      )
    ) {
      message = 'postcodeRequired';
    }
    handleApiError(e, message);
  };

}

export default DoddleStores;
