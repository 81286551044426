// @flow

import { apiClient } from '_common/api/clients/clients';
import type { TPeriscopeFilter } from 'dashboard/stores/dashboardStore';
import { MAIN_COMPANIES } from '_common/constants/appConfig';
import { NAMES, APPLICATION_NAME, SPACES } from '_common/constants/dashboard';

export type TDashboardConfigResponse = {
  name: string,
  src: string,
};

const getDashboardConfig = async (
  organisationId: string,
  rootOrgId: string,
  dashboardName: string = NAMES.PBD_OPS_PERFORMANCE
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.DOODLE}/${APPLICATION_NAME}/getUrl/dashboard/${dashboardName}/organisation/${organisationId}/${rootOrgId}`
  );

  return response.data.resource;
};

const getDashboardConfigDrillDown = async (
  organisationId: string,
  rootOrgId: string,
  filter: TPeriscopeFilter,
  dashboardName: string = NAMES.PBD_OPS_PERFORMANCE
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.DOODLE}/${APPLICATION_NAME}/getUrl/dashboard/${dashboardName}-dd/organisation/${organisationId}/${rootOrgId}?${filter.filter_name}=${filter.column_value}`
  );

  return response.data.resource;
};

const getApHostDashboardConfig = async (
  organisationId: string,
  rootOrgId: string
): Promise<TDashboardConfigResponse> => {
  const url = `/v1/periscope-dashboard/${SPACES.APAC}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}/organisation/${organisationId}/${rootOrgId}`;
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getApHostDashboardConfigDrillDown = async (
  organisationId: string,
  rootOrgId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.APAC}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}-dd/organisation/${organisationId}/${rootOrgId}?${filter.filter_name}=${filter.column_value}`
  );

  return response.data.resource;
};

const getYamatoDashboardConfig = async (
  organisationId: string
): Promise<TDashboardConfigResponse> => {
  const url = `/v1/periscope-dashboard/${SPACES.YAMATO}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.OPS_SUMMARY}/organisation/${organisationId}`;
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getYamatoDashboardConfigDrillDown = async (
  organisationId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.YAMATO}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.OPS_SUMMARY}-dd/organisation/${organisationId}?${filter.filter_name}=${filter.column_value}`
  );

  return response.data.resource;
};

const getAmazonDashboardConfig = async (
  organisationId: string
): Promise<TDashboardConfigResponse> => {
  const url = `/v1/periscope-dashboard/${SPACES.AMAZON}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.RETURNS_KIOSK}/organisation/${organisationId}`;
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getAmazonDashboardConfigDrillDown = async (
  organisationId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.AMAZON}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.RETURNS_KIOSK}/organisation/${organisationId}?${filter.filter_name}=${filter.column_value}`
  );

  return response.data.resource;
};

const getStarlinksHostDashboardConfig = async (
  organisationId: string,
  rootOrgId: string
): Promise<TDashboardConfigResponse> => {
  let url = `/v1/periscope-dashboard/${SPACES.STARLINKS}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}/organisation/${organisationId}`;
  if (organisationId !== MAIN_COMPANIES.STARLINKS_ID) {
    url = `${url}/${rootOrgId}`;
  }
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getStarlinksHostDashboardConfigDrillDown = async (
  organisationId: string,
  rootOrgId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  let url = `/v1/periscope-dashboard/${SPACES.STARLINKS}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}-dd/organisation/${organisationId}`;
  if (organisationId !== MAIN_COMPANIES.STARLINKS_ID) {
    url = `${url}/${rootOrgId}`;
  }
  url = `${url}?${filter.filter_name}=${filter.column_value}`;
  const response = await apiClient.get(url);

  return response.data.resource;
};

const getUspsHostDashboardConfig = async (
  organisationId: string,
  rootOrgId: string
): Promise<TDashboardConfigResponse> => {
  let url = `/v1/periscope-dashboard/${SPACES.USPS}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}/organisation/${organisationId}`;
  if (organisationId !== MAIN_COMPANIES.USPS_ID) {
    url = `${url}/${rootOrgId}`;
  }
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getUspsHostDashboardConfigDrillDown = async (
  organisationId: string,
  rootOrgId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  let url = `/v1/periscope-dashboard/${SPACES.USPS}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HOST_SUMMARY}-dd/organisation/${organisationId}`;
  if (organisationId !== MAIN_COMPANIES.USPS_ID) {
    url = `${url}/${rootOrgId}`;
  }
  url = `${url}?${filter.filter_name}=${filter.column_value}`;
  const response = await apiClient.get(url);

  return response.data.resource;
};

const getAsdaDashboardConfig = async (
  organisationId: string
): Promise<TDashboardConfigResponse> => {
  const url = `/v1/periscope-dashboard/${SPACES.ASDA}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HANDHELD}/organisation/${organisationId}`;
  const response = await apiClient.get(url);
  return response.data.resource;
};

const getAsdaDashboardConfigDrillDown = async (
  organisationId: string,
  filter: TPeriscopeFilter
): Promise<TDashboardConfigResponse> => {
  const response = await apiClient.get(
    `/v1/periscope-dashboard/${SPACES.ASDA}/${APPLICATION_NAME}/getUrl/dashboard/${NAMES.HANDHELD}-dd/organisation/${organisationId}?${filter.filter_name}=${filter.column_value}`
  );

  return response.data.resource;
};

export default {
  getDashboardConfig,
  getApHostDashboardConfig,
  getDashboardConfigDrillDown,
  getApHostDashboardConfigDrillDown,
  getYamatoDashboardConfig,
  getYamatoDashboardConfigDrillDown,
  getAmazonDashboardConfig,
  getAmazonDashboardConfigDrillDown,
  getStarlinksHostDashboardConfig,
  getStarlinksHostDashboardConfigDrillDown,
  getUspsHostDashboardConfig,
  getUspsHostDashboardConfigDrillDown,
  getAsdaDashboardConfig,
  getAsdaDashboardConfigDrillDown,
};
